
const ourServicesList = [
  {
    title: 'Understand the market',
    shortDescription: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    link: '/consulting/market-assessment-services',
    imageSrc: 'img/home/png/understand-the-market.png',
    altText: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    buttonTitle: 'Know More',
  },
  {
    title: 'Estimate the forecast',
    shortDescription: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    link: '/report-store.php?category=Market Insight',
    imageSrc: 'img/home/png/estimate-the-forecast.png',
    altText: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    buttonTitle: 'Know More',
  },
  {
    title: 'Analyze the pipeline',
    shortDescription: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    link: '/report-store.php?category=Pipeline Insight',
    imageSrc: 'img/home/png/analyse-the-pipeline.png',
    altText: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    buttonTitle: 'Know More',
  },
  {
    title: 'Monitor the competitors',
    shortDescription: 'Know and inspect your competitors to stand out in the industry',
    link: '/consulting/competitive-intelligence-services',
    imageSrc: 'img/home/png/monitor-the-competitors.png',
    altText: 'Know and inspect your competitors to stand out in the industry',
    buttonTitle: 'Know More',
  },
  {
    title: 'Streamline your journey',
    shortDescription: 'Consulting services that help in meeting desired goals through optimized strategy',
    link: '/consulting/asset-prioritizaton-services',
    imageSrc: 'img/home/png/streamline-your-journey.png',
    altText: 'Consulting services that help in meeting desired goals through optimized strategy',
    buttonTitle: 'Know More',
  },
  {
    title: 'Accelerate your growth',
    shortDescription: 'Exploit innovative ideas through our in-depth and curated R&D services',
    link: '/consulting/business-development-services',
    imageSrc: 'img/home/png/accelerate-your-growth.png',
    altText: 'Exploit innovative ideas through our in-depth and curated R&D services',
    buttonTitle: 'Know More',
  }
];
const accordionList = [
  {
    title: 'Customizable Reports',
    content: 'Customized reports specific to geographies, clinical trials, epidemiological trends, and markets.',
    link: '/report-store.php',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Solutions',
    content: 'Identify your needs, and our analysts will provide granular-level market intelligence to fulfill them.',
    link: '/request-for-proposal',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Competitive Intelligence',
    content: 'Custom-based analysis of the competitors to meet your unique needs.',
    link: '/consulting/competitive-intelligence-services',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Packages',
    content: 'Hassle-free subscription-based report packages the way you desire through our PharmDelve.',
    link: '/pharmdelve',
    buttonTitle: 'Know More',
  },
];
const counterList = [
  {
    title: 'Reports',
    imageSrc: 'img/home/svg/research_reports.svg',
    altText: 'Reports',
    numbers: '7000',
  },
  {
    title: 'Consulting Projects',
    imageSrc: 'img/home/svg/consulting_projects.svg',
    altText: 'Consulting Projects',
    numbers: '500',
  },
  {
    title: 'Satisfied Clients',
    imageSrc: 'img/home/svg/satisfied_clients.svg',
    altText: 'Satisfied Clients',
    numbers: '1000',
  },
  {
    title: 'Customized Reports',
    imageSrc: 'img/home/svg/customized_reports.svg',
    altText: 'Customized Reports',
    numbers: '1200',
  },
];
const blogList = [
  {
    title: "The Future of Multiple System Atrophy Treatment: Pioneering Approaches Beyond Levodopa Reliance",
    shortDescription: "Multiple system atrophy (MSA) is a rare and progressive neurodegenerative disorder that affects the autonomic nervous system, motor function, and balance. It manifests through a combination of parkinsonism, autonomic dysfunction, and cerebellar ataxia, making it distinct from Parkinson’s disease despite overlapping symptoms.",
    link: "/blog/future-of-multiple-system-atrophy-treatment",
    imageSrc: "img/home/png/the-future-of-multiple-system-atrophy-treatment-pioneering-approaches-beyond-levodopa-reliance.png",
    altText: "The Future of Multiple System Atrophy Treatment: Pioneering Approaches Beyond Levodopa Reliance",
  },
  {
    title: "Graves’ Disease Drug Pipeline: 6 Late-Stage Therapies to Watch",
    shortDescription: "The pipeline for Graves’ disease drug treatments is rapidly expanding, with prominent companies such as Immunovant, Viridian Therapeutics, Argenx, Tourmaline Bio, Hoffmann-La Roche, and Sling Therapeutics spearheading the development of targeted therapies for thyroid disorders. Promising candidates in the late stages of Graves’ disease treatment include veligrotug (VRDN-001), VRDN-003, batoclimab, Efgartigimod PH20 SC, ENSPRYNG (satralizumab, RG6168), and IMVT-1402.",
    link: "/blog/graves-disease-drugs-in-late-stage-development",
    imageSrc: "img/home/png/graves-disease-drug-pipeline-6-late-stage-therapies-to-watch.png",
    altText: "Graves’ Disease Drug Pipeline: 6 Late-Stage Therapies to Watch",
  },
  {
    title: "8 Game-Changing Applications of Healthcare Mobility Solutions",
    shortDescription: " In today’s fast-paced world, healthcare mobility solutions are transforming the way care is delivered, making it smarter, faster, and more accessible than ever. From real-time patient monitoring and seamless teleconsultations to efficient appointment management and medication tracking, these solutions are bridging the gap between patients and providers.",
    link: "/blog/applications-of-healthcare-mobility-solutions",
    imageSrc: "img/home/png/8-game-changing-applications-of-healthcare-mobility-solutions.png",
    altText: "8 Game-Changing Applications of Healthcare Mobility Solutions",
  },
  {
    title: "Thyroid Eye Disease: The Hidden Impact of Thyroid Dysfunction on Eye Health",
    shortDescription: "Thyroid eye disease is a rare but serious autoimmune condition that affects the eyes, often linked to hyperthyroidism or Graves’ disease. Around 25-50% of people with Graves’ disease develop TED, with women being more commonly affected than men.",
    link: "/blog/thyroid-eye-disease-treatment",
    imageSrc: "img/home/png/thyroid-eye-disease-the-hidden-impact-of-thyroid-dysfunction-on-eye-health.png",
    altText: "Thyroid Eye Disease: The Hidden Impact of Thyroid Dysfunction on Eye Health",
  },
  {
    title: "Graves’ Disease Treatment Revolution: What’s Next in Line?",
    shortDescription: "Affecting around 1 in 200 people, Graves’ disease is the most common cause of an overactive thyroid. More than 12% of the US population will develop a thyroid condition during their lifetime and an estimated 20 million Americans have some form of thyroid disease.",
    link: "/blog/graves-disease-treatment-revolution",
    imageSrc: "img/home/png/graves-disease-treatment-revolution-whats-next-in-line.png",
    altText: "Graves’ Disease Treatment Revolution: What’s Next in Line?",
  },
  {
    title: "Everything You Need to Know About Acute Kidney Injury",
    shortDescription: "Acute Kidney Injury (AKI) is a significant medical condition that affects millions of individuals worldwide each year. It is a rapidly progressive condition that requires timely diagnosis and treatment to prevent severe complications or long-term consequences. AKI is particularly prevalent in hospitalized patients, especially those receiving critical care, highlighting its global importance in healthcare settings.",
    link: "/blog/acute-kidney-injury-treatment-landscape",
    imageSrc: "img/home/png/everything-you-need-to-know-about-acute-kidney-injury.png",
    altText: "Everything You Need to Know About Acute Kidney Injury",
  },
];
const reportList = {
  content: [
    {
      title: 'Latest Reports',
      description: 'Get comprehensive insights into the healthcare industry and epidemiology-based market forecasts with a 360° panoramic picture of the market alongside the latest trends, developments, and future opportunities.',
      shortTitle: 'Report Store',
      shortDescription: 'With over 7000 research reports in the repository covering 27 therapeutic areas, stay updated about the most recent trends, news, and developments in the healthcare domain.',
      buttonTitle: 'Explore Now',
    },
  ],
  items: [
    {
      link: '/report-store/thymidine-kinase-2-deficiency-tk2d-market',
      imageSrc: 'products/report-image/Thymidine-Kinase-2-Deficiency-Market.png',
      altText: 'Thymidine Kinase 2 Deficiency Market',
    },
    {
      link: '/report-store/interleukin-2-market-forecast',
      imageSrc: 'products/report-image/Interleukin-2-Market.png',
      altText: 'Interleukin-2 Market',
    },
    {
      link: '/report-store/spinal-cord-injury-market',
      imageSrc: 'products/report-image/Spinal-Cord-Injury-Market.png',
      altText: 'Spinal Cord Injury Market',
    },
    {
      link: '/report-store/multiple-system-atrophy-msa-market',
      imageSrc: 'products/report-image/Multiple-System-Atrophy-Market.png',
      altText: 'Multiple System Atrophy Market',
    },
    {
      link: '/report-store/preeclampsia-market',
      imageSrc: 'products/report-image/Preeclampsia-Market.png',
      altText: 'Preeclampsia Market',
    },
  ],
};
export {
  ourServicesList,
  accordionList,
  counterList,
  blogList,
  reportList,
}