import React, { useEffect, useState } from 'react';
import CommonForm from "../Common/CommonForm";
import { useCommonEnquiryMutation } from '../Store/Features/CommonFormAPI';
import { useRouter } from 'next/navigation';
import { encode } from '../../utils/encrypt';
import Loading from '../PopupForm/Loading';


export default function CallBackForm({ options, formTitle, conference_name = null, fields, page_name = "Common Page", captcha = false, form_name = "common-form", endpoint = null, selectdFields = null, product_name = null, anchor_url = null, services = null, setSelectedServices, custom_fields=null, redirect=null , slug=null}) {
  const [scrolled, setScrolled] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');
  const [formAPICallBack, { isLoading, error }] = useCommonEnquiryMutation();
  const { push } = useRouter();
  const [LoadingStatus, setLoadingStatus] = useState(false);


  useEffect(() => {
    if (services) {
      setSelectedServices(services);
    }
  }, [services, setSelectedServices]);

  const handleSubmit = (values) => {
    setLoadingStatus(true);
    values.page_name = page_name;
    values.form_name = form_name;
    values.source_url = window.location.href;
    values.source_url_from = document.referrer;

    if (anchor_url) {
      values.anchor_url = anchor_url;
    }

    if (product_name) {
      values.product_name = product_name;
    }

    if (conference_name) {
      values.conference_name = conference_name;
    }

    if (services) {
      values.services = services;
    }

    if(custom_fields){
      for(let i=0; i<custom_fields.length;i++){
        values[custom_fields[i].key]=custom_fields[i].value
      }
    }

    // const blockedUrls = [
    //   'r87\.com',
    //   'r87\.com\/\?"onmouseover="nsdom[12]\(this\)"a="',
    // ];

    // Create regex patterns from the blocked URLs
    // const blockedPatterns = blockedUrls.map(url => new RegExp(url, 'i'));

    // Check if any pattern matches either the referer or nextUrl
    // const isBlocked = blockedPatterns.some(pattern =>
    //     pattern.test(values.source_url_from)
    // );
    // if ((values.source_url_from && isBlocked) || (values.email && values.email.includes("example.com"))) {
    //   setTimeout(() => {
    //     if (redirect) {
    //       push(redirect);
    //     } else {
    //       console.log("Callback Form : ", slug);
    //       if (slug) {
    //         sessionStorage.setItem('data', JSON.stringify({ slug: slug }));
    //       }
    //       push('/thank-you');
    //     }
    //     setLoadingStatus(false);
    //   }, 1000);
    //   return;
    // }    
    // values.validation_token = captchaToken;
    formAPICallBack({
      data: { data: encode(values) },
      url: endpoint
    }).then((response) => {
      if (response && response.data) {
        if (response.data.code === 200) {
          setTimeout(() => {
            if(redirect){
              push(redirect)
            }else{
              console.log( "Callback Form : ",slug);
              if(slug){
                sessionStorage.setItem('data', JSON.stringify({ slug: slug }));
                push('/thank-you');
              }else{
                push('/thank-you');
              }
            }
            setLoadingStatus(false);
          }, 1000);
        } else {
          alert(response.data.message || "An error occurred");
        }
      } else {
        alert("An error occurred");
      }
    });
  };

  return (
    <>
      <Loading status={LoadingStatus} />
      <CommonForm
        fields={fields}
        formTitle={formTitle}
        onSubmit={handleSubmit}
        selectedFields={selectdFields}
        options={options}
        captcha={captcha === false ? setCaptchaToken : false}
        isLoading={isLoading}
        page_name={page_name}
        form_name={form_name}
        // services={services}
      />
    </>
  );
}