import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Image from "next/image";
const DButton = dynamic(() => import('../elements/DButton', { ssr: false }));
const AutocompleteWithPreview = dynamic(() => import('../Algolia/SearchFile', { ssr: false }));
import Link from 'next/link';

export default function Subheader({ background, mobileBackground=false, title, description, device = 'desktop' }) {


  return (
    <>
      {process.env.IS_SALE == 'active'  ?
        (
          <div className={(device == 'mobile') ? 'flex items-center relative w-full mx-auto mt-65 homeBannerMarginTop h-[520px] bg-gradient-to-r from-[#4396c0] to-[#1a2f73]' : 'flex items-center relative mt-65 homeBannerMarginTop w-full mx-auto h-[520px]  bg-gradient-to-r from-[#4396c0] to-[#1a2f73]'}>
            {(device == 'desktop') ?
              <>
                <Image
                  src={background}
                  className={(device == 'mobile') ? 'w-full h-[520px] d-none' : 'w-full h-[520px] min-h-full lg:block hidden'}
                  priority={true}
                  quality={(device == 'mobile') ? 40 : 80}
                  alt={"Background Image"}
                  fill
                  style={{ objectFit: 'cover' }}
                />
                <Image
                  src={mobileBackground}
                  className={(device == 'mobile') ? 'w-full h-[520px] d-none' : 'w-full h-[520px] min-h-full lg:hidden block'}
                  priority={true}
                  quality={(device == 'mobile') ? 40 : 80}
                  alt={"Background Image"}
                  fill
                  style={{ objectFit: 'cover' }}
                />
              </>
              :
              <></>
            }
            <div className="absolute flex flex-col mx-auto xl:max-w-screen max-w-screen-2xl lg:left-16 left-4 lg:right-0 right-4 lg:justify-between">
              <div className='flex flex-col items-center justify-between w-full lg:flex-row'>
                <div className='flex flex-col items-center mb-5 lg:my-0'>
                  <h1 className={`text-3xl font-semibold text-white`}>{title}</h1>
                  <p className='mx-0 mb-6 text-white'>{description}</p>
                  <div className='w-full'>
                    <AutocompleteWithPreview />
                  </div>
                  <div className='flex justify-center w-full gap-2 lg:justify-start'>
                    <DButton title="Report Store" redirect="/report-store.php" />
                    <DButton title="Business Consulting" redirect="/consulting" />
                  </div>
                </div>
                <div className='flex flex-col items-center'>
                  <Link href="/report-store.php">
                    <Image
                      src={process.env.IMG_CDN + "img/home/png/sale-discount.png"}
                      className="xl:w-[600px] lg:w-[300px] w-[250px] lg:block xl:mr-40 xl:mt-10 lg:mr-20 mr-0"
                      priority={true}
                      width={600}
                      height={400}
                      alt={"Sale Discounted"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
        :
        (
          <div className={(device == 'mobile') ? 'flex items-center relative w-full mx-auto mt-65 homeBannerMarginTop h-[520px] bg-gradient-to-r from-[#4396c0] to-[#1a2f73]' : 'flex items-center relative mt-65 homeBannerMarginTop w-full mx-auto h-[520px]  bg-gradient-to-r from-[#4396c0] to-[#1a2f73]'}>
            {(device == 'desktop') ?
              <Link href="/report-store.php">
                <Image
                  src={background}
                  className={(device == 'mobile') ? 'w-full h-[520px] d-none' : 'w-full h-[520px] min-h-full lg:block hidden'}
                  priority={true}
                  quality={(device == 'mobile') ? 40 : 80}
                  alt={"Background Image"}
                  fill
                  style={{ objectFit: 'cover' }}
                />
                <Image
                  src={mobileBackground}
                  className={(device == 'mobile') ? 'w-full h-[520px] d-none' : 'w-full h-[520px] min-h-full lg:hidden block'}
                  priority={true}
                  quality={(device == 'mobile') ? 40 : 80}
                  alt={"Background Image"}
                  fill
                  style={{ objectFit: 'cover' }}
                />
              </Link>
              :
              <></>
            }
            <div className={`w-11/12 xl:max-w-screen max-w-screen-2xl absolute xl:left-0 lg:left-16 left-4 lg:right-0 right-4 flex flex-col mx-auto lg:justify-between`}>
              <h1 className={`text-3xl font-semibold text-white`}>{title}</h1>
              <p className='mx-0 mb-6 text-white'>{description}</p>
              <div style={{ maxWidth: '500px' }}>
                <AutocompleteWithPreview />
              </div>
              <div className='flex gap-2'>
                <DButton title="Report Store" redirect="/report-store.php" />
                <DButton title="Business Consulting" redirect="/consulting" />
              </div>
            </div>
          </div>
        )
    }
    </>
  )
}