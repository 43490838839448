// components/Modal.js
import React from "react";
import Modal from "react-modal";
import CallBackForm from "../Common/CallBackForm";
// Modal.setAppElement("#__next");

const CommonPopup = ({isOpen, closeModal, conference_name="", product_name, fields, formTitle, options, page_name, form_name, endpoint="", anchor_url="", footer=null, captcha=false, custom_fields=null, slug = null}) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-100" isOpen={isOpen} onRequestClose={closeModal} contentLabel="My Modal">
      <div className="fixed inset-0 bg-black opacity-50 modal"></div>
      <div className="modal-content bg-white rounded-lg shadow-lg z-10 lg:w-[40%] w-[90%]">
        <div className="flex justify-between p-4 modal-header">
          <h2 className="text-lg ml-auto mr-auto font-semibold text-[#1b5df3]">{formTitle}</h2>
          <button className="text-lg text-red-400 hover:text-red-400" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <hr />
        <div className="w-full h-full p-5 overflow-x-hidden overflow-y-auto modal-body max-h-96">
          <CallBackForm 
            fields={fields} 
            options={options} 
            custom_fields={custom_fields}
            page_name={page_name} 
            form_name={form_name} 
            conference_name={conference_name}
            className="mx-10"
            endpoint={endpoint}
            anchor_url={anchor_url}
            product_name={product_name}
            captcha={captcha}
            slug={slug}
          />
        </div>
        {(footer) && 
        <div>
         {footer}
        </div>
        }
      </div>
    </div>
  )
}
export default CommonPopup;
